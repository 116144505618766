import './navbar.css'

const Navbar = () =>{
    return (
        <div className="nav" >
            <a href="/" className="home-button">
                Home
            </a>
        </div>
    )
}

export default Navbar;