import Home from './components/home/home.jsx'
import Navbar from './components/navbar/navbar.jsx';
import NotFound from './components/notfound/notfound.jsx'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import './App.css';


function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Navbar/>
      <div className="content">
      <Switch>
        <Route exact path ="/">
          <Home/>
        </Route>
        <Route path="*">
          <NotFound/>
        </Route>
      </Switch>
      </div>
    </div>
    </BrowserRouter>
  );
}

export default App;
