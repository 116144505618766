import './home.css'

const Home = () =>  {
    return (
        <div className="home">
        <h1 className="title">My Website</h1>
        <h2 className="name">by Bronson Zell</h2>
        </div> 
     );
}

export default Home;