import './notfound.css'

// 404 Not Found page

const NotFound = () => {
  return (
    <div className="not-found">
      <h2>404: Page Not Found</h2>
    </div>
  );
};

export default NotFound;
